<div class="mt-4 p-4" *ngIf="!confirmationView">
    <div class="row">
        <div class="col-lg-8 mb-lg-0">
            <nav aria-label="breadcrumb" class="cart-nav">
                <ol class="breadcrumb mt-3">
                    <li class="breadcrumb-item"><a [href]="'./cart/' + cartId">Cart</a></li>
                    <li class="breadcrumb-item"><a [href]="'./cart/' + cartId">{{cart.cartName}}</a></li>
                    <li class="breadcrumb-item active" aria-current="page">Checkout</li>
                </ol>
            </nav>
        </div>
        <div class="col-4"></div>
    </div>
    <div class="row" *ngIf="cart">
        <div class="card col-lg-8 mb-5 mb-lg-0">
            <form [formGroup]="form">
                <mdb-accordion [multiple]="true" class="border-0">
                    <ng-container *ngIf="shippingSummary === false; else shippingSummaryTemplate">
                        <mdb-accordion-item class="border-0" [collapsed]="false">
                            <ng-template mdbAccordionItemHeader>Shipping details</ng-template>
                            <ng-template mdbAccordionItemBody>

                                <h6 class="fw-bolder">Shipping address</h6>
                                <p>Please enter the address you would like to ship your order too below.</p>

                                <div class="row mb-4">
                                    <div class="col">
                                        <h6 class="fw-bolder">First name</h6>
                                        <mdb-form-control>
                                            <input mdbInput type="text" class="form-control border"
                                                placeholder="First name" [value]="" formControlName="firstName">
                                        </mdb-form-control>
                                    </div>
                                    <div class="col">
                                        <h6 class="fw-bolder">Last name</h6>
                                        <mdb-form-control>
                                            <input mdbInput type="text" class="form-control border"
                                                placeholder="Last name" formControlName="lastName">
                                        </mdb-form-control>
                                    </div>
                                </div>

                                <div class="row mb-4">
                                    <div class="col">
                                        <h6 class="fw-bolder">Phone</h6>
                                        <mdb-form-control class="form-outline">
                                            <input mdbInput type="tel" class="form-control border" placeholder="Phone"
                                                formControlName="phone">

                                            <mdb-error class="position-absolute" style="top: auto;" *ngIf="
                                                phone?.invalid && (phone?.dirty || phone?.touched)
                                                ">Phone number is required.</mdb-error>
                                        </mdb-form-control>
                                    </div>
                                </div>

                                <div class="row mb-4">
                                    <div class="col">
                                        <h6 class="fw-bolder">Address</h6>
                                        <mdb-form-control class="">

                                            <input mdbInput type="text" class="form-control border" appGooglePlaces
                                                [options]="addressInputOptions"
                                                (addressChange)="handleAddressChange($event)"
                                                formControlName="street1" />

                                            <mdb-error
                                                *ngIf="addressVerificationError || street1?.invalid && (street1?.dirty || street1?.touched)"
                                                style="position: absolute;">Unable to verify address. Please
                                                enter a valid address.</mdb-error>
                                        </mdb-form-control>
                                    </div>
                                </div>

                                <div class="row mb-4">
                                    <div class="col">
                                        <mdb-form-control class="">
                                            <input mdbInput type="text" class="form-control border"
                                                placeholder="Apartment, suite, etc. (optional)"
                                                formControlName="street2">
                                        </mdb-form-control>
                                    </div>
                                </div>

                                <div class="row mb-4">
                                    <div class="col">
                                        <h6 class="fw-bolder">City</h6>
                                        <mdb-form-control>
                                            <input mdbInput type="text" class="form-control border" placeholder="City"
                                                formControlName="city" (blur)="verifyAddressIfAllFieldsFilled()">

                                            <mdb-error class="position-absolute" style="top: auto;" *ngIf="
                                                    city?.invalid && (city?.dirty || city?.touched)
                                                    ">City is required.
                                            </mdb-error>
                                        </mdb-form-control>
                                    </div>
                                    <div class="col">
                                        <h6 class="fw-bolder">State</h6>
                                        <mdb-form-control>
                                            <input mdbInput type="text" class="form-control border" placeholder="State"
                                                formControlName="state" (blur)="verifyAddressIfAllFieldsFilled()">

                                            <mdb-error class="position-absolute" style="top: auto;" *ngIf="
                                                    state?.invalid && (state?.dirty || state?.touched)
                                                    ">State is required.
                                            </mdb-error>
                                        </mdb-form-control>
                                    </div>
                                    <div class="col">
                                        <h6 class="fw-bolder">Zip</h6>
                                        <mdb-form-control>
                                            <input mdbInput type="number" class="form-control border"
                                                placeholder="Zip" formControlName="zip"
                                                (blur)="verifyAddressIfAllFieldsFilled()">

                                            <mdb-error class="position-absolute" style="top: auto;" *ngIf="
                                                    zip?.invalid && (zip?.dirty || zip?.touched)
                                                    ">Zip is required.
                                            </mdb-error>
                                        </mdb-form-control>
                                    </div>
                                </div>

                                <div class="row mb-4">
                                    <div class="col">
                                        <h6 class="fw-bolder">Country</h6>
                                        <mdb-form-control class="border rounded">

                                            <mdb-select [filter]="true" placeholder="Select a country..."
                                                formControlName="country" (focusout)="verifyAddressIfAllFieldsFilled()">
                                                <mdb-option *ngFor="let country of countries" [value]="country.value">{{
                                                    country.label }}</mdb-option>
                                            </mdb-select>

                                            <mdb-error class="position-absolute" style="top: auto;" *ngIf="
                                                country?.invalid && (country?.dirty || country?.touched)
                                                ">Country is required.
                                            </mdb-error>
                                        </mdb-form-control>
                                    </div>
                                </div>

                                <div class="row">
                                    <h6 class="fw-bolder">Select your shipping rate</h6>
                                    <p>We ship anywhere in the United States. All products are made to order. Rates
                                        reflect production time & shipping.</p>
                                </div>
                                <div class="row">
                                    <div class="col-12">
                                        <button class="checkout-custom-container mb-2"
                                            *ngFor="let shippingRate of shippingRates"
                                            (click)="applyShippingRate(shippingRate)"
                                            [ngClass]="{active: currentRate?.option === shippingRate.option}">
                                            <div class="checkout-flex-container">
                                                <div class="checkout-flex-item">
                                                    <div class="checkout-title">{{shippingRate.title}}</div>
                                                    <div class="checkout-description">{{shippingRate.description}}</div>
                                                </div>
                                                <div *ngIf="shippingRate.deliveryDate"
                                                    class="checkout-title d-flex align-items-center">
                                                    Estimate Delivery Date: {{shippingRate.deliveryDate}}
                                                </div>
                                                <div *ngIf="shippingRate.rate != 0" class="checkout-price">
                                                    <b>{{shippingRate.rate | currency}}</b>
                                                </div>
                                            </div>
                                        </button>
                                    </div>
                                </div>                                
                                <div class="row">
                                    <div class="col-md-4 offset-md-8 d-md-flex justify-content-md-end">
                                        <button type="button"
                                            class="btn btn-outline-primary mt-2 mt-md-0 me-2 w-100 w-md-25 cancel-section"
                                            (click)="cancelSection('shippingSummary')">
                                            Cancel
                                        </button>
                                        <button type="button" class="btn btn-primary mt-2 mt-md-0 w-100 w-md-25"
                                            style="background: var(--primary-background, #2B374D);"
                                            [disabled]="addressVerificationError"
                                            (click)="saveSection('shippingSummary')">
                                            Save
                                        </button>
                                    </div>
                                </div>
                            </ng-template>
                        </mdb-accordion-item>
                    </ng-container>
                    <ng-container *ngIf="orderSummary === false; else orderSummaryTemplate">
                        <mdb-accordion-item class="border-0" [collapsed]="false">
                            <ng-template mdbAccordionItemHeader>Order details</ng-template>
                            <ng-template mdbAccordionItemBody>
                                <div class="row">
                                    <h6 class="fw-bolder">Estimated Delivery Date</h6>
                                </div>
                                <div class="row">
                                    <input mdbInput type="text" class="form-control border"
                                        placeholder="MM/DD/YYYY" [value]="cart.deliveryDate"
                                        formControlName="deliveryDate" [readonly]="true" />
                                </div>

                                <div class="row mb-2">
                                    <h6 class="fw-bolder mt-4">Anything else we need to know? (Optional)</h6>
                                    <textarea mdbInput class="form-control border" id="form6Example7" rows="4"
                                        placeholder="Add additional notes..." [value]="cart.notes"
                                        formControlName="notes"></textarea>
                                </div>
                                <div class="row">
                                    <div class="col-md-4 offset-md-8 d-md-flex justify-content-md-end">
                                        <button type="button"
                                            class="btn btn-outline-primary mt-2 mt-md-0 me-2 w-100 w-md-25 cancel-section"
                                            (click)="cancelSection('orderSummary')">
                                            Cancel
                                        </button>
                                        <button type="button" class="btn btn-primary mt-2 mt-md-0 w-100 w-md-25"
                                            style="background: var(--primary-background, #2B374D);"
                                            (click)="saveSection('orderSummary')">
                                            Save
                                        </button>
                                    </div>
                                </div>
                            </ng-template>
                        </mdb-accordion-item>
                    </ng-container>
                    <ng-container *ngIf="contactSummary === false; else contactSummaryTemplate">
                        <mdb-accordion-item class="border-0" [collapsed]="false">
                            <ng-template mdbAccordionItemHeader>Contact details</ng-template>
                            <ng-template mdbAccordionItemBody>
                                <div class="mb-2">
                                    <input mdbRadio class="form-check-input" type="radio" id="contactLabelSame" aria-label="..." value="sameContact"
                                        (click)="contactlinkCollapse.hide()" formControlName="selectedContactOption"
                                        [attr.aria-expanded]="!contactlinkCollapse.collapsed"
                                        aria-controls="multiCollapseExample1" />
                                    Same as shipping details
                                </div>

                                <div>
                                    <input mdbRadio class="form-check-input" type="radio" id="contactLabelDifferent"
                                        aria-label="..." value="differentContact"
                                        (click)="contactlinkCollapse.show()" formControlName="selectedContactOption"
                                        [attr.aria-expanded]="!contactlinkCollapse.collapsed"
                                        aria-controls="multiCollapseExample1" />
                                    Use different contact details
                                </div>
                                <div class="collapse multi-collapse mt-3" id="multiCollapseExample1" mdbCollapse
                                    #contactlinkCollapse="mdbCollapse">
                                    <div class="row mb-4">
                                        <div class="col">
                                            <h6 class="fw-bolder">First name</h6>
                                            <mdb-form-control>
                                                <input mdbInput type="text" id="form6Example1"
                                                    class="form-control border" placeholder="Enter first name"
                                                    [value]="this.cart.contact?.firstName"
                                                    formControlName="firstNameContact" />
                                            </mdb-form-control>
                                        </div>
                                        <div class="col">
                                            <h6 class="fw-bolder">Last name</h6>
                                            <mdb-form-control>
                                                <input mdbInput type="text" id="form6Example2"
                                                    class="form-control border" placeholder="Enter last name"
                                                    [value]="this.cart.contact?.lastName"
                                                    formControlName="lastNameContact" />
                                            </mdb-form-control>
                                        </div>
                                    </div>
                                    <div class="row mb-4">
                                        <div class="col">
                                            <h6 class="fw-bolder">Email</h6>
                                            <mdb-form-control>
                                                <input mdbInput type="email" id="form6Example1"
                                                    class="form-control border" placeholder="Enter email"
                                                    [value]="this.cart.contact?.email" formControlName="emailContact" />
                                            </mdb-form-control>
                                        </div>
                                        <div class="col">
                                            <h6 class="fw-bolder">Phone number</h6>
                                            <mdb-form-control>
                                                <input mdbInput type="tel" id="form6Example2"
                                                    class="form-control border" placeholder="ex: 000-000-0000"
                                                    [value]="this.cart.contact?.phone" formControlName="phoneContact" />
                                            </mdb-form-control>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-4 offset-md-8 d-md-flex justify-content-md-end">
                                        <button type="button"
                                            class="btn btn-outline-primary mt-2 mt-md-0 me-2 w-100 w-md-25 cancel-section"
                                            (click)="cancelSection('contactSummary')">
                                            Cancel
                                        </button>
                                        <button type="button" class="btn btn-primary mt-2 mt-md-0 w-100 w-md-25"
                                            style="background: var(--primary-background, #2B374D);"
                                            (click)="saveSection('contactSummary')">
                                            Save
                                        </button>
                                    </div>
                                </div>
                            </ng-template>
                        </mdb-accordion-item>
                    </ng-container>
                    <ng-container *ngIf="paymentSummary === false; else paymentSummaryTemplate">
                        <mdb-accordion-item class="border-0" [collapsed]="false">
                            <ng-template mdbAccordionItemHeader>Payment method</ng-template>
                            <ng-template mdbAccordionItemBody>
                                <div class="row mb-2">
                                    <div class="mb-4">
                                        <input mdbRadio class="form-check-input" type="radio" name="cardLabel"
                                            id="cardLabel2" value="" aria-label="..."
                                            (click)="linkCollapseAddCard.show()"
                                            [attr.aria-expanded]="linkCollapseAddCard.collapsed"
                                            aria-controls="multiCollapseExample2" />
                                        Add new card
                                        <i class="fab fa-cc-visa fa-lg me-1"></i> <i
                                            class="fab fa-cc-mastercard fa-lg me-1"></i> <i
                                            class="fab fa-cc-amex fa-lg"></i>
                                    </div>
                                    <div id="multiCollapseExample2" mdbCollapse #linkCollapseAddCard="mdbCollapse">
                                        <div class="row mb-2">
                                            <div class="col">
                                                <h6 class="fw-bolder">Name on Card</h6>
                                                <mdb-form-control class="">
                                                    <input mdbInput type="text" class="form-control border"
                                                        placeholder="Enter Full name" formControlName="name">
                                                </mdb-form-control>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col">
                                                <h6 class="fw-bolder" style="display: inline;">Card number </h6><span
                                                    style="font-size: 12px;">(Enter card number without spaces or
                                                    dashes)</span>
                                                <iframe id="tokenframe" name="tokenframe" frameborder="0" scrolling="no"
                                                    width="100%" height="40"
                                                    src="https://fts.cardconnect.com/itoke/ajax-tokenizer.html?css=input%7Bwidth%3A100%25%3Bcolor%3Argba(110%2C117%2C132%2C1)%3Bborder%3A1px%20solid%20%23bdbdbd%3Bfont-size%3A15px%3Bline-height%3A26px%3Bpadding%3A0.33rem%20.75rem%200.33rem%20.75rem%3Bbox-sizing%3Aborder-box%3Bborder-radius%3A0.25rem%3B%7Dbody%7Bmargin%3A0%3B%7D&placeholder=%30%31%32%33%30%34%35%36%30%37%38%39%30%31%30%30"></iframe>
                                                <input type="hidden" name="mytoken" id="mytoken" />
                                            </div>
                                        </div>
                                        <div class="row mb-4">
                                            <div class="col">
                                                <h6 class="fw-bolder">Expiration date</h6>
                                                <mdb-form-control>
                                                    <input mdbInput type="text" class="form-control border"
                                                        placeholder="MMYY" maxlength="4" formControlName="expiry">
                                                </mdb-form-control>
                                            </div>
                                            <div class="col">
                                                <h6 class="fw-bolder">CVV</h6>
                                                <mdb-form-control>
                                                    <input mdbInput type="text" class="form-control border"
                                                        placeholder="Security code" maxlength="4"
                                                        formControlName="cvv2">
                                                </mdb-form-control>
                                            </div>
                                        </div>
                                    </div>

                                    <div>
                                        <h6 class="fw-bolder">Billing address</h6>
                                        <p class="text-muted">Select the address that matches your card or payment
                                            method.
                                        </p>
                                    </div>
                                    <div class="mb-2">
                                        <input mdbRadio class="form-check-input" type="radio" id="billingLabelSame"
                                            aria-label="..." value="same" (click)="linkCollapse.hide()"
                                            formControlName="selectedBillingOption"
                                            [attr.aria-expanded]="!linkCollapse.collapsed"
                                            aria-controls="multiCollapseExample1" />
                                        Same as shipping address
                                    </div>

                                    <div>
                                        <input mdbRadio class="form-check-input" type="radio" id="billingLabelDifferent"
                                            aria-label="..." value="different"
                                            (click)="linkCollapse.show()" formControlName="selectedBillingOption"
                                            [attr.aria-expanded]="!linkCollapse.collapsed"
                                            aria-controls="multiCollapseExample1" />
                                        Use different billing address
                                    </div>

                                    <div class="collapse multi-collapse mt-3" id="multiCollapseExample1" mdbCollapse #linkCollapse="mdbCollapse">

                                        <div class="row mb-4">
                                            <div class="col">
                                                <h6 class="fw-bolder">First name</h6>
                                                <mdb-form-control>
                                                    <input mdbInput type="text" class="form-control border"
                                                        placeholder="First name" formControlName="firstNameBilling">
                                                </mdb-form-control>
                                            </div>
                                            <div class="col">
                                                <h6 class="fw-bolder">Last name</h6>
                                                <mdb-form-control>
                                                    <input mdbInput type="text" class="form-control border"
                                                        placeholder="Last name" formControlName="lastNameBilling">
                                                </mdb-form-control>
                                            </div>
                                        </div>

                                        <div class="row mb-4">
                                            <div class="col">
                                                <h6 class="fw-bolder">Address</h6>
                                                <mdb-form-control class="">
                                                    <input mdbInput type="text" class="form-control border"
                                                        placeholder="Address" formControlName="street1Billing">

                                                    <mdb-error
                                                        *ngIf="addressVerificationError || street1Billing?.invalid && (street1Billing?.dirty || street1Billing?.touched)"
                                                        style="position: absolute;">Unable to verify address. Please
                                                        enter a valid address.</mdb-error>
                                                </mdb-form-control>
                                            </div>
                                        </div>

                                        <div class="row mb-4">
                                            <div class="col">
                                                <mdb-form-control class="">
                                                    <input mdbInput type="text" class="form-control border"
                                                        placeholder="Apartment, suite, etc. (optional)"
                                                        formControlName="street2Billing">
                                                </mdb-form-control>
                                            </div>
                                        </div>

                                        <div class="row mb-4">
                                            <div class="col">
                                                <h6 class="fw-bolder">City</h6>
                                                <mdb-form-control>
                                                    <input mdbInput type="text" class="form-control border"
                                                        placeholder="City" formControlName="cityBilling">

                                                    <mdb-error class="position-absolute" style="top: auto;" *ngIf="
                                                            cityBilling?.invalid && (cityBilling?.dirty || cityBilling?.touched)
                                                            ">City is required.
                                                    </mdb-error>
                                                </mdb-form-control>
                                            </div>
                                            <div class="col">
                                                <h6 class="fw-bolder">State</h6>
                                                <mdb-form-control>
                                                    <input mdbInput type="text" class="form-control border"
                                                        placeholder="State" formControlName="stateBilling">

                                                    <mdb-error class="position-absolute" style="top: auto;" *ngIf="
                                                            stateBilling?.invalid && (stateBilling?.dirty || stateBilling?.touched)
                                                            ">State is required.
                                                    </mdb-error>
                                                </mdb-form-control>
                                            </div>
                                            <div class="col">
                                                <h6 class="fw-bolder">Postal Code</h6>
                                                <mdb-form-control>
                                                    <input mdbInput type="number" class="form-control border"
                                                        placeholder="Postal Code" formControlName="zipBilling">

                                                    <mdb-error class="position-absolute" style="top: auto;" *ngIf="
                                                            zipBilling?.invalid && (zipBilling?.dirty || zipBilling?.touched)
                                                            ">Postal code is required.
                                                    </mdb-error>
                                                </mdb-form-control>
                                            </div>
                                        </div>
                                        <div class="mt-3 row mb-4">
                                            <div class="col">
                                                <h6 class="fw-bolder">Country</h6>
                                                <mdb-form-control class="border rounded">
                                                    <mdb-select mdbValidate [filter]="true"
                                                        placeholder="Select a country..."
                                                        formControlName="countryBilling">
                                                        <mdb-option *ngFor="let country of countries"
                                                            [value]="country.value">{{
                                                            country.label }}</mdb-option>
                                                    </mdb-select>

                                                    <mdb-error class="position-absolute" style="top: auto;" *ngIf="
                                                        country?.invalid && (country?.dirty || country?.touched)
                                                        ">Country is required.
                                                    </mdb-error>
                                                </mdb-form-control>
                                            </div>
                                        </div>

                                        <div class="row mb-4">
                                            <div class="col">
                                                <h6 class="fw-bolder">Phone</h6>
                                                <mdb-form-control class="form-outline">
                                                    <input mdbInput type="tel" class="form-control border"
                                                        placeholder="Phone" formControlName="phoneBilling">

                                                    <mdb-error class="position-absolute" style="top: auto;" *ngIf="
                                                        phoneBilling?.invalid && (phoneBilling?.dirty || phoneBilling?.touched)
                                                        ">Phone number is required.</mdb-error>
                                                </mdb-form-control>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-md-4 offset-md-8 d-md-flex justify-content-md-end">
                                        <button type="button"
                                            class="btn btn-outline-primary mt-2 mt-md-0 me-2 w-100 w-md-25 cancel-section"
                                            (click)="cancelSection('paymentSummary')">
                                            Cancel
                                        </button>
                                        <button type="button" class="btn btn-primary mt-2 mt-md-0 w-100 w-md-25"
                                            style="background: var(--primary-background, #2B374D);"
                                            (click)="saveSection('paymentSummary')">
                                            Save
                                        </button>
                                    </div>
                                </div>
                            </ng-template>
                        </mdb-accordion-item>
                    </ng-container>
                </mdb-accordion>
            </form>
        </div>

        <div class="col-lg-4">
            <div class="card sticky-top">
                <div class="card-body">
                    <div class="d-flex justify-content-between pb-3 mb-3">
                        <h6 class="mb-0">Subtotal</h6>
                        <h6 class="mb-0">{{ calculateGrandSubtotal() | currency }}</h6>
                    </div>
                    <div class="d-flex justify-content-between pb-3 mb-3">
                        <h6 class="mb-0">Shipping</h6>
                        <h6 class="mb-0">{{ currentRate?.rate | currency }}</h6>
                    </div>
                    <div *ngIf="currentRate?.rushFee > 0" class="d-flex justify-content-between pb-3 mb-3">
                        <h6 class="mb-0">Rush Fee</h6>
                        <h6 class="mb-0">{{ currentRate?.rushFee | currency }}</h6>
                    </div>
                    <div class="d-flex justify-content-between border-bottom pb-3 mb-3">
                        <h6 class="mb-0">Tax</h6>
                        <h6 class="mb-0">{{tax | currency}}</h6>
                    </div>
                    <div class="d-flex justify-content-between mb-4">
                        <input mdbInput type="text" class="form-control border me-2"
                            placeholder="Promo or Discount code" [(ngModel)]="promoCode">
                        <button type="button" class="btn btn-primary"
                            style="background: var(--primary-background, #2B374D);"
                            (click)="checkPromoCode()">Apply</button>
                        <mdb-error class="position-absolute" style="top: auto; margin-top: 35px; margin-left: 25px;"
                            *ngIf="
                            promoCodeInvalid
                            ">{{promoCodeErrorMessage}}</mdb-error>
                    </div>
                    <div *ngIf="discount?.discount > 0"
                        class="d-flex justify-content-between pb-3 mb-3">
                        <h6 class="mb-0">Discount</h6>
                        <h6 class="mb-0">({{ totalDiscount | currency }})</h6>
                    </div>
                    <div class="d-flex justify-content-between mb-3">
                        <p class="mb-2"><span class="h6">Total</span></p>
                        <h6>{{calculateGrandTotal() | currency}}</h6>
                    </div>
                    <p class="small mb-4">You are currently checking out for Cart: <b>{{cart.cartName}}</b>. Clicking
                        "place
                        order" will submit your order to Culture Studio & you will receive a confirmation email shortly
                        after.
                        If there are any questions pertaining to your order, our team will reach out via email.</p>
                    <div class="d-flex justify-content-center mb-5">
                        <re-captcha (resolved)="resolved($event)" [siteKey]="recaptchaKey"></re-captcha>
                    </div>
                    <button type="button" class="btn btn-primary btn-block" style="background: var(--primary-background, #2B374D);" (click)="submitOrder()" [disabled]="!isNotARobot">Place
                        order</button>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-container *ngIf="confirmationView">
    <div class="">
        <div class="card">
            <div class="card-body">
                <div class="p-5 text-center bg-light">
                    <h1 class="mb-3">Thank you for your purchase! <i class="far fa-check-circle"></i></h1>
                    <p class="mb-3">Your order has been successfully placed.</p>
                    <p class="mb-3">You will receive a confirmation email shortly on your purchase.</p>
                    <p class="mb-3">View your order: <a
                            [href]="'/orders/' + legacyOrderNumber">{{legacyOrderNumber}}</a></p>
                    <button type="button" class="btn btn-outline-primary" style="color: #2B374D; border-color: #2B374D;"
                        [routerLink]="'/catalog'">Continue
                        Shopping</button>
                </div>
            </div>
        </div>
    </div>

    <div class="ps-4 pe-4 pb-4">
        <div class="row mt-4">
            <ng-container *ngIf="section1">
                <h3 class="base-font fs-4">{{productSectionName1}}</h3>
                <div class="d-flex justify-content-between">
                    <div class="clearfix d-flex align-items-center pe-2">
                        <i class="fas fa-chevron-left float-start" (click)="scrollLeft(overflowSection1)"
                            *ngIf="section1.length > 6"></i>
                    </div>
                    <div class="custom-scroll-main" #overflowSection1>
                        <div class="col-sm-2" *ngFor="let product of section1">
                            <div class="bg-image hover-overlay ripple rounded px-1" data-mdb-ripple-color="light">
                                <a href="{{product.url}}">
                                    <img loading="lazy" src="{{product.productImage.fields.file.url}}" class="img-fluid"
                                        [alt]="'Image of ' + product.brand + ' ' + product.styleId + ' ' +  product.name" />
                                    <div class="mask" style="background-color: rgba(251, 251, 251, 0.2);"></div>
                                </a>
                                <div class="">
                                    <span class="fw-bold">
                                        {{ product.brand }}
                                    </span>
                                    <span>
                                        {{ product.styleId }} {{ product.name }}
                                    </span>
                                </div>
                                <div>
                                    <span class="fw-lighter">
                                        in {{ product.category }}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="clearfix d-flex align-items-center pe-2">
                        <i class="fas fa-chevron-right float-end" (click)="scrollRight(overflowSection1)"
                            *ngIf="section1.length > 6"></i>
                    </div>
                </div>
            </ng-container>
        </div>
        <div class="row mt-4">
            <ng-container *ngIf="section2">
                <h3 class="base-font fs-4">{{productSectionName2}}</h3>
                <div class="d-flex justify-content-between">
                    <div class="clearfix d-flex align-items-center pe-2">
                        <i class="fas fa-chevron-left float-start" (click)="scrollLeft(overflowSection2)"
                            *ngIf="section2.length > 6"></i>
                    </div>
                    <div class="custom-scroll-main" #overflowSection2>
                        <div class="col-sm-2" *ngFor="let product of section2">
                            <div class="bg-image hover-overlay ripple rounded px-1" data-mdb-ripple-color="light">
                                <a href="{{product.url}}">
                                    <img loading="lazy" src="{{product.productImage.fields.file.url}}" class="img-fluid"
                                        [alt]="'Image of ' + product.brand + ' ' + product.styleId + ' ' +  product.name" />
                                    <div class="mask" style="background-color: rgba(251, 251, 251, 0.2);"></div>
                                </a>
                                <div class="">
                                    <span class="fw-bold">
                                        {{ product.brand }}
                                    </span>
                                    <span>
                                        {{ product.styleId }} {{ product.name }}
                                    </span>
                                </div>
                                <div>
                                    <span class="fw-lighter">
                                        in {{ product.category }}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="clearfix d-flex align-items-center pe-2">
                        <i class="fas fa-chevron-right float-end" (click)="scrollRight(overflowSection2)"
                            *ngIf="section2.length > 6"></i>
                    </div>
                </div>
            </ng-container>
        </div>
        <div class="row mt-4">
            <ng-container *ngIf="section3">
                <h3 class="base-font fs-4">{{productSectionName3}}</h3>
                <div class="d-flex justify-content-between">
                    <div class="clearfix d-flex align-items-center pe-2">
                        <i class="fas fa-chevron-left float-start" (click)="scrollLeft(overflowSection3)"
                            *ngIf="section3.length > 6"></i>
                    </div>
                    <div class="custom-scroll-main" #overflowSection3>
                        <div class="col-sm-2" *ngFor="let product of section3">
                            <div class="bg-image hover-overlay ripple rounded px-1" data-mdb-ripple-color="light">
                                <a href="{{product.url}}">
                                    <img loading="lazy" src="{{product.productImage.fields.file.url}}" class="img-fluid"
                                        [alt]="'Image of ' + product.brand + ' ' + product.styleId + ' ' +  product.name" />
                                    <div class="mask" style="background-color: rgba(251, 251, 251, 0.2);"></div>
                                </a>
                                <div class="">
                                    <span class="fw-bold">
                                        {{ product.brand }}
                                    </span>
                                    <span>
                                        {{ product.styleId }} {{ product.name }}
                                    </span>
                                </div>
                                <div>
                                    <span class="fw-lighter">
                                        in {{ product.category }}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="clearfix d-flex align-items-center pe-2">
                        <i class="fas fa-chevron-right float-end" (click)="scrollRight(overflowSection3)"
                            *ngIf="section3.length > 6"></i>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
</ng-container>
<cs-footer></cs-footer>


<ng-template #orderSummaryTemplate>
    <div class="order-summary p-4">
        <div class="clearfix mb-2">
            <div class="float-start">
                <b>Order details</b>
            </div>
            <div class="float-end cursor-pointer" (click)="editSection('orderSummary')">
                <i class="fas fa-pen me-2"></i>Edit
            </div>
        </div>
        <div class="summary-text">
            <b>Estimate fulfillment date:</b> <span> {{cart.deliveryDate}}</span>
        </div>
        <div class="summary-text">
            <b>Additional notes:</b> {{cart.notes ? cart.notes : 'N/A'}}
        </div>
    </div>
</ng-template>

<ng-template #contactSummaryTemplate>
    <div class="order-summary p-4">
        <div class="clearfix mb-2">
            <div class="float-start">
                <b>Contact details</b>
            </div>
            <div class="float-end cursor-pointer" (click)="editSection('contactSummary')">
                <i class="fas fa-pen me-2"></i>Edit
            </div>
        </div>
        <div class="summary-text">
            {{cart.contact.firstName}} {{cart.contact.lastName}}
        </div>
        <div class="summary-text">
            {{cart.contact.email}}
        </div>
        <div class="summary-text">
            {{cart.contact.phone}}
        </div>
    </div>
</ng-template>

<ng-template #shippingSummaryTemplate>
    <div class="order-summary p-4">
        <div class="clearfix mb-2">
            <div class="float-start">
                <b>Shipping details</b>
            </div>
            <div class="float-end cursor-pointer" (click)="editSection('shippingSummary')">
                <i class="fas fa-pen me-2"></i>Edit
            </div>
        </div>
        <div class="summary-text">
            {{cart.shipping?.[0]?.firstName}} {{cart.shipping?.[0]?.lastName}}
        </div>
        <div class="summary-text">
            {{cart.shipping?.[0]?.address}}
        </div>
        <div *ngIf="cart.shipping?.[0]?.address2" class="summary-text">
            {{cart.shipping[0].address2}}
        </div>
        <div class="summary-text">
            {{cart.shipping?.[0]?.city}} {{cart.shipping?.[0]?.state}}
        </div>
        <div class="summary-text">
            {{cart.shipping[0].zip}}
        </div>
        <br>
        <div class="summary-text">
            Rate: {{currentRate?.description}}
        </div>
    </div>
</ng-template>

<ng-template #paymentSummaryTemplate>
    <div class="order-summary p-4">
        <div class="clearfix mb-2">
            <div class="float-start">
                <b>Payment method</b>
            </div>
            <div class="float-end cursor-pointer" (click)="editSection('paymentSummary')">
                <i class="fas fa-pen me-2"></i>Edit
            </div>
        </div>
        <div class="summary-text" *ngIf="cart?.payment?.account">
            Card ending in: {{getLastFourDigits(cart.payment.account)}}
        </div>
        <div class="summary-text">
            {{cart.payment?.billingAddress?.address}}
        </div>
        <div *ngIf="cart.payment?.billingAddress?.address2" class="summary-text">
            {{cart.payment.billingAddress.address2}}
        </div>
        <div class="summary-text">
            {{cart.payment?.billingAddress?.city}} {{cart.payment?.billingAddress?.state}}
        </div>
        <div class="summary-text">
            {{cart.payment?.billingAddress?.zip}}
        </div>
    </div>
</ng-template>