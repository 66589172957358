import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { EasypostService } from '@core/services/easypost/easypost.service';
import { environment } from "src/environments/environment"
import * as fromCatalog from '@core/state/catalog/catalog.selectors';
import { Store, select } from '@ngrx/store';
import { Subject, takeUntil } from 'rxjs';
import { HelperService } from '@core/services/api/helper.service';
import * as CatalogActions from '@core/state/catalog/catalog.actions';
import { MongoDbService } from '@core/services/api/mongodb.service';
import { SessionService } from '@core/services/session/session.service';
import { ContentfulService } from '@core/services/cms/contentful.service';
import { GlobalLoaderService } from '@core/services/global-loader.service';
import { MetaService } from '@core/services/seo/meta-tag-service';
import { Cart, CartPayment, CartShipping, PromoCode } from '@core/models/catalog';
import { DataLayerService } from '@core/services/seo/data-layer.service';
import * as OrderActions from '@core/state/order/order.actions';
import * as fromOrders from '@core/state/order/order.selectors';
import { SharedService } from '@shared/shared.service';
export interface ShippingRate {
  description: string;
  service: any;
  title: string;
  option: string;
  rate: number;
  rushFee?: number;
  deliveryDate: string;
}
@Component({
  selector: 'app-cart-checkout',
  templateUrl: './cart-checkout.component.html',
  styleUrls: ['./cart-checkout.component.scss'],
})
export class CartCheckoutComponent implements OnInit {
  form: FormGroup;
  recaptchaKey: string;
  recaptchaToken: string;
  addressVerificationError = false;
  tokenError = true;
  cart: Cart;
  cartId: string;
  orderSummary: boolean = false;
  contactSummary: boolean = false;
  shippingSummary: boolean = false;
  paymentSummary: boolean = false;
  shipping: CartShipping;
  subtotal: number = 0;
  tax: number = 0;
  grandTotal: number = 0;
  token: string;
  confirmationView: boolean = false;
  errorPlacingOrder: boolean = false;
  boxCount: number = 0;
  shippingRates: ShippingRate[] = [];
  section1: any;
  section2: any;
  section3: any;
  productSectionName1: any;
  productSectionName2: any;
  productSectionName3: any;
  clientId: any;
  countries = [
    { value: 'USA', label: 'United States' },
  ];
  validatedAddress: any;
  currentRate: ShippingRate;
  promoCode: string;
  addressInputOptions: any = {
    componentRestrictions: { country: 'US' }
  }
  discount: PromoCode;
  totalDiscount: number;
  promoCodeInvalid: boolean = false;
  promoCodeErrorMessage: string;
  legacyOrderNumber: any;
  destroyed$ = new Subject<void>();
  subtotalAmount: number;
  isNotARobot = false

  constructor(
    private fb: FormBuilder,
    private easyPost: EasypostService,
    private route: ActivatedRoute,
    private store: Store,
    private helper: HelperService,
    private _mongo: MongoDbService,
    protected session: SessionService,
    private sharedService: SharedService,
    private contentfulService: ContentfulService,
    private globalLoaderService: GlobalLoaderService,
    private metaService: MetaService,
    protected router: Router,
    private dataLayer: DataLayerService
  ) {
    this.route.params.subscribe(params => {

      if(params && !params['orderId']) {
        this.cartId = params['id']; // Access the 'id' parameter from the route
  
        this.store.select(fromCatalog.selectCartById(this.cartId))
        .pipe(takeUntil(this.destroyed$))
        .subscribe((cart => {
          this.cart = cart
  
          this.metaService.updateMetaTags(
            `Checkout: ${this.cart.cartName ? this.cart.cartName : ''}`,
            `Purchase your cart in the Catalog within the Culture Studio Platform`,
            `Purchase your cart in the Catalog within the Culture Studio Platform`,
            `https://img.stokkup.com/app/cs-circle-logo.png`,
          )
          this.shipping = this.cart?.shipping?.[0] ?? {} as CartShipping;
          this.orderSummary = !!this.cart?.deliveryDate
          this.contactSummary = !!this.cart.contact?.firstName
          this.shippingSummary = this.cart?.shipping?.[0]?.address && this.tax > 0 ? true : false;
          this.paymentSummary = !!this.cart.payment?.account
  
          this.generateBoxCount();
  
        }));
      } else {
        this.legacyOrderNumber = params['orderId']
        this.confirmationView = true // init confirmation view

        this.store.dispatch(OrderActions.getOrderDetailV2({displayNumber: this.legacyOrderNumber, companyId: 100015}))

        this.store.pipe(select(fromOrders.selectOrdersDetailV2), takeUntil(this.destroyed$)).subscribe((orderDetail: any) => {
          if(orderDetail && orderDetail.order_total) {
            this.subtotalAmount = orderDetail.order_total 
            this.dataLayer.conversionShareASales('purchase', this.legacyOrderNumber, this.subtotalAmount)
          }
        });
      }
    });
    this.recaptchaKey = environment.recaptchaKey
  }


 ngOnInit() {
    if(!this.confirmationView) {
      this.session.getUserClient().subscribe(({ id: clientId }) => {
        this.clientId = clientId;
      });
    
      this.createForm(); // create form after data is available
      window.addEventListener('message', this.tokenHandler.bind(this), false);
    } else {
      //init confirmation view
      this.initContentfulContent();
    }
  }

  handleAddressChange(address: any) {
    if(address?.formatted_address){
      const googleAddressInfo = this.extractAddressInfo(address.formatted_address);

      this.form.get('street1').setValue(googleAddressInfo.address);
      this.form.get('city').setValue(googleAddressInfo.city);
      this.form.get('state').setValue(googleAddressInfo.state);
      this.form.get('zip').setValue(googleAddressInfo.zip);
      this.form.get('country').setValue(googleAddressInfo.country);

      this.verifyAddressIfAllFieldsFilled();
    }
  }

  extractAddressInfo(formattedAddress) {
    // Split the formatted address into parts using ', ' as the separator
    const addressParts = formattedAddress.split(', ');

    // Extract address, city, state, and zip based on their positions in the array
    const address = addressParts[0];
    const city = addressParts[1];

    const parseCityZip = addressParts[2].split(' ');
    const state = parseCityZip[0];
    const zip = parseCityZip[1];
    const country = addressParts[3];

    // Return the extracted information
    return {
      address,
      city,
      state,
      zip,
      country
    };
  }

  tokenHandler(event: MessageEvent) {
    if (event && event.data && typeof event.data === 'string' && this.isValidJson(event.data)) {
      try {
        const tokenRes = JSON.parse(event.data);
        if (tokenRes && tokenRes.message) {
          this.token = tokenRes.message;

          if (this.form) {
            this.form.get('account').setValue(this.token);
            this.tokenError = false;
          }
        }
      } catch (error) {
        console.error('Error parsing event data:', error);
      }
    }
  }

  isValidJson(data: string): boolean {
    try {
      return !!JSON.parse(data)
    } catch (error) {
      return false
    }
  }

  createForm() {
    this.form = this.fb.group({
      //Order details
      deliveryDate: [this.cart.deliveryDate ? this.cart.deliveryDate : ''],
      notes: [this.cart.notes ? this.cart.notes : null],

      selectedContactOption: ['sameContact'],
      //Contact details
      firstNameContact: [this.cart.contact?.firstName],
      lastNameContact: [this.cart.contact?.lastName],
      emailContact: [this.cart.contact?.email],
      phoneContact: [this.cart.contact?.phone],

      //Shipping details
      country: new FormControl(null, { validators: Validators.required, updateOn: 'blur' }),
      firstName: [this.shipping?.firstName ? this.shipping.firstName : null],
      lastName: [this.shipping?.lastName ? this.shipping.lastName : null],
      street1: [this.shipping?.street1 ? this.shipping.street1 : null, Validators.required],
      street2: [this.shipping?.street2 ? this.shipping.street2 : null],
      city: [this.shipping?.city ? this.shipping.city : null, Validators.required],
      state: [this.shipping?.state ? this.shipping.state : null, Validators.required],
      zip: [this.shipping?.zip ? this.shipping.zip : null, Validators.required],
      phone: [this.shipping?.phone ? this.shipping.phone : null, Validators.required],

      // cardName: ['', Validators.required],
      //Payment method
      name: ['',],
      account: ['',],
      expiry: new FormControl('', [Validators.pattern(/^\d*$/)]),
      cvv2: new FormControl('', [Validators.pattern(/^\d*$/)]),
      amount: [''],

      countryBilling: new FormControl(null, { validators: Validators.required, updateOn: 'blur' }),
      firstNameBilling: ['',],
      lastNameBilling: ['',],
      street1Billing: ['', Validators.required],
      street2Billing: [''],
      cityBilling: ['', Validators.required],
      stateBilling: ['', Validators.required],
      zipBilling: ['', Validators.required],
      phoneBilling: ['', Validators.required],
      selectedBillingOption: ['same'],
    });
  }

  get deliveryDate(): AbstractControl {
    return this.form.get('deliveryDate')
  }
  get notes(): AbstractControl {
    return this.form.get('notes')
  }
  get country(): AbstractControl {
    return this.form.get('country')!;
  }
  get street1(): AbstractControl {
    return this.form.get('street1')!;
  }
  get city(): AbstractControl {
    return this.form.get('city')!;
  }
  get state(): AbstractControl {
    return this.form.get('state')!;
  }
  get zip(): AbstractControl {
    return this.form.get('zip')!;
  }
  get phone(): AbstractControl {
    return this.form.get('phone')!;
  }

  get countryBilling(): AbstractControl {
    return this.form.get('country')!;
  }
  get street1Billing(): AbstractControl {
    return this.form.get('street1')!;
  }
  get cityBilling(): AbstractControl {
    return this.form.get('city')!;
  }
  get stateBilling(): AbstractControl {
    return this.form.get('state')!;
  }
  get zipBilling(): AbstractControl {
    return this.form.get('zip')!;
  }
  get phoneBilling(): AbstractControl {
    return this.form.get('phone')!;
  }

  get name(): AbstractControl {
    return this.form.get('name');
  }

  get account(): AbstractControl {
    return this.form.get('account');
  }

  get expiry(): AbstractControl {
    return this.form.get('expiry');
  }

  get cvv2(): AbstractControl {
    return this.form.get('cvv2');
  }

  getVariantQtys(variants) {
    let sum = 0;

    for (const variant of variants) {
      if (variant.price) {
        sum += variant.qty;
      }
    }

    return sum;
  }

  generateBoxCount() {
    if (this.cart && this.cart.designs) {
      for (const design of this.cart.designs) {
        this.boxCount = (this.getVariantQtys(design.products.variants) / 72);
      }

      if(this.boxCount > 1) {
        this.boxCount = Math.floor(this.boxCount);
      } else {
        this.boxCount = 1;
      }
    }
  }

  verifyAddressIfAllFieldsFilled() {
    const formData = this.form.value;

    if (this.form.value.country && this.form.value.street1 && this.form.value.city && this.form.value.state && this.form.value.zip) {
      // Call your easypost.verifyAddress service with formData
      this.easyPost.verifyAddress(formData).subscribe(
        (response) => {
          //get shipping rate pricing from easypost
          this.updateShippingRatePricing(response);
          this.validatedAddress = response
          // Reset error and set success if verification is successful
          this.addressVerificationError = false;
        },
        (error) => {
          // Handle any errors from your service
          console.error(error);

          // Set error and reset success if verification fails
          this.addressVerificationError = true;
        }
      );
    }
  }

  getProductPrice(variants) {
    let sum = 0;

    for (const variant of variants) {
      if (variant.price) {
        sum += (variant.price * variant.qty);
      }
    }

    return sum;
  }

  editSection(section) {
    if (section === 'orderSummary') {
      this.orderSummary = false;
    }
    if (section === 'contactSummary') {
      this.contactSummary = false;
    }
    if (section === 'shippingSummary') {
      this.shippingSummary = false;
    }
    if (section === 'paymentSummary') {
      this.paymentSummary = false;
    }
  }

  cancelSection(section) {
    if (section === 'orderSummary') {
      this.orderSummary = true;
    }
    if (section === 'contactSummary') {
      this.contactSummary = true;
    }
    if (section === 'shippingSummary') {
      this.shippingSummary = true;
    }
    if (section === 'paymentSummary') {
      this.paymentSummary = true;
    }
  }

  saveSection(section) {
    if (section === 'orderSummary') {
      const updateCart = {
        ...this.cart, _id: undefined, version: this.helper.getTimestamp(),
        deliveryDate: typeof this.form.value.deliveryDate === 'object' ? this.helper.formatDateForCart(this.form.value.deliveryDate) : this.form.value.deliveryDate ? this.form.value.deliveryDate : this.cart.deliveryDate,
        notes: this.form.value.notes ?? this.cart.notes,
        tax: this.tax,
      };

      this.store.dispatch(CatalogActions.addCart({
        cart: updateCart
      }))
    }
    if (section === 'contactSummary') {
      const newContact = this.getContactDetails()
      // const newContact = {
      //   firstName: this.form.value.firstNameContact || this.cart.contact.firstName,
      //   lastName: this.form.value.lastNameContact || this.cart.contact.lastName,
      //   email: this.form.value.emailContact || this.cart.contact.email,
      //   phone: this.form.value.phoneContact || this.cart.contact.phone,
      // }

      const updateCart = {
        ...this.cart, _id: undefined, version: this.helper.getTimestamp(),
        contact: newContact
      };

      this.store.dispatch(CatalogActions.addCart({
        cart: updateCart
      }))
    }
    if (section === 'shippingSummary') {
      const newShipping = [{
        country: this.form.value.country || this.cart.shipping[0].country,
        firstName: this.form.value.firstName || this.cart.shipping[0].firstName,
        lastName: this.form.value.lastName || this.cart.shipping[0].lastName,
        address: this.form.value.street1 || this.cart.shipping[0].address,
        address2: this.form.value.street2 ? this.form.value.street2 : this.cart.shipping[0] && this.cart.shipping[0].address2 ? this.cart.shipping[0].address2 : '',
        city: this.form.value.city || this.cart.shipping[0].city,
        state: this.form.value.state || this.cart.shipping[0].state,
        zip: this.form.value.zip || this.cart.shipping[0].zip,
        phone: this.form.value.phone || this.cart.shipping[0].phone,
      }]

      const updateCart = {
        ...this.cart, _id: undefined, version: this.helper.getTimestamp(),
        shipping: newShipping
      };

      this.store.dispatch(CatalogActions.addCart({
        cart: updateCart
      }))
    }
    if (section === 'paymentSummary') {
      if(this.tokenError) {
        this.openAlert('Unable to verify card. Please enter a valid card number.');
        return;
      } else {
        const newBillingAddress = this.getBillingAddress()
        const newPayment: CartPayment = {
          billingAddress: newBillingAddress,
          name: this.form.value.name,
          account: this.form.value.account,
          expiry: this.form.value.expiry,
          cvv2: this.form.value.cvv2,
          amount: this.form.value.amount,
        }

        const updateCart: Cart = {
          ...this.cart,
          _id: undefined,
          version: this.helper.getTimestamp(),
          payment: newPayment
        }

        this.store.dispatch(CatalogActions.addCart({
          cart: updateCart
        }))
      }
    }

    this.store.pipe(select(fromCatalog.selectCartById(this.cartId))).subscribe((cart => {
      this.cart = cart
      this.shipping = this.cart?.shipping?.[0] ?? {} as CartShipping;
      this.orderSummary = !!this.cart?.deliveryDate
      this.contactSummary = !!this.cart?.contact?.firstName
      this.shippingSummary = !!this.cart?.shipping?.[0]?.address
      this.paymentSummary = !!this.cart?.payment?.account
    }));
  }

  getContactDetails() {
    if(this.form.value.selectedContactOption === 'sameContact') {
      return {
        firstName: this.form.value.firstName,
        lastName: this.form.value.lastName,
        email: this.session.getUser().email ? this.session.getUser().email : '',
        phone: this.form.value.phone,
      }
    } else if (this.form.value.selectedContactOption === 'differentContact') {
      return {
        firstName: this.form.value.firstNameContact,
        lastName: this.form.value.lastNameContact,
        email: this.form.value.emailContact,
        phone: this.form.value.phoneContact,
      }
    }
  }

  getBillingAddress() {
    if (this.form.value.selectedBillingOption === 'same') {
      return this.shipping
    } else if (this.form.value.selectedBillingOption === 'different') {
      return {
        country: this.form.value.countryBilling || this.cart.payment.countryBilling,
        firstName: this.form.value.firstNameBilling || this.cart.payment.firstNameBilling,
        lastName: this.form.value.lastNameBilling || this.cart.payment.lastNameBilling,
        address: this.form.value.street1Billing || this.cart.payment.addressBilling,
        address2: this.form.value.street2Billing || this.cart.payment?.address2Billing,
        city: this.form.value.cityBilling || this.cart.payment.cityBilling,
        state: this.form.value.stateBilling || this.cart.payment.stateBilling,
        zip: this.form.value.zipBilling || this.cart.payment.zipBilling,
        phone: this.form.value.phoneBilling || this.cart.payment.phoneBilling,
      } as CartShipping
    } else {
      return {} as CartShipping;
    }
  }

 async submitOrder() {
    if (!this.isNotARobot) return
    if (this.shippingSummary && this.orderSummary && this.contactSummary && this.paymentSummary) {
      this.globalLoaderService.setMessage('Placing your order. This may take a few minutes...')
      this.globalLoaderService.show()

      try {
        const submitCart = { ...this.cart, platform_user_id: this.session.getUser().id, shippingCost: this.currentRate?.rate ?? 0, tax: this.tax, rushFee: this.currentRate?.rushFee ?? 0,  discount: this.totalDiscount && this.totalDiscount > 0 ? Math.ceil(this.totalDiscount * 100) / 100 : 0 };
        const legacyOrderNumber = await this._mongo.placeOrder(submitCart, this.recaptchaToken);
        if(legacyOrderNumber) {
          this.legacyOrderNumber = legacyOrderNumber

          await this.submitTax(this.validatedAddress, this.currentRate)
          if(this.discount && this.discount.promoCode) {
            await this._mongo.addDiscountTransaction(this.discount.promoCode, this.totalDiscount, legacyOrderNumber, submitCart.cartId)
          }
          const paidCart = {...this.cart, paid : true, version: this.helper.getTimestamp(), subTotal: this.subtotal , tax: this.tax , grandTotal: this.grandTotal}
          this.store.dispatch(CatalogActions.addCart({
            cart: paidCart
          }))

          this.router.navigate([`cart/confirmation/${this.legacyOrderNumber}`]) // navigate to confirmation page
        }
        // Process the result if needed
      } catch (error) {
        // Handle errors if necessary
        this.errorPlacingOrder = true;
        this.openAlert('Error: ' + (error?.error?.message ?? 'An unknown error occurred while we were trying to place your order'))
      } finally {
        this.globalLoaderService.hide()
      }
    } else {
      this.openAlert('Please complete each section to place an order.');
    }
  }

  initContentfulContent(){
    this.contentfulService.getProductSection1().subscribe(
      (data) => {
        this.section1 = data;
        this.productSectionName1 = this.section1?.[0]?.sectionName ?? '';
      },
      (error) => {
        console.error('Error:', error);
      }
    )

    this.contentfulService.getProductSection2().subscribe(
      (data) => {
        this.section2 = data;
        this.productSectionName2 = this.section2?.[0]?.sectionName ?? '';
      },
      (error) => {
        console.error('Error:', error);
      }
    )

    this.contentfulService.getProductSection3().subscribe(
      (data) => {
        this.section3 = data;
        this.productSectionName3 = this.section3?.[0]?.sectionName ?? '';
      },
      (error) => {
        console.error('Error:', error);
      }
    )
  }

  openAlert(prompt) {
    this.sharedService.showNotification({ data: { title: prompt, class: '' }, autohide: true });
  }

  updateShippingRatePricing(verifiedAddress) {
    const toAddress = {
      country: 'US',
      name: `${this.form.value.firstName || this.cart.shipping[0].firstName} ${this.form.value.lastName || this.cart.shipping[0].lastName}`,
      street1: verifiedAddress.street1 || this.cart.shipping[0].address,
      street2: this.form.value.street2 ? this.form.value.street2 : this.cart.shipping[0] && this.cart.shipping[0].address2 ? this.cart.shipping[0].address2 : '',
      city: verifiedAddress.city || this.cart.shipping[0].city,
      state: verifiedAddress.state || this.cart.shipping[0].state,
      zip: verifiedAddress.zip || this.cart.shipping[0].zip,
    }

    const request = this.easyPost.formatShippingRateDTO(toAddress);

    this.easyPost.getShippingRates(request).subscribe(
      (response) => {
        // Handle the response from your service
        this.transformShippingRates(response.rates)
      },
      (error) => {
        // Handle any errors from your service
        console.error(error);
      }
    );
  }

  formatTaxDTO(address) {
    const avalaraShipTo = {
      "line1": address.street1,
      "city": address.city,
      "region": address.state,
      "country": address.country,
      "postalCode": address.zip
    }

    return avalaraShipTo
  }


  getTotalProductQty(variants): number{
    let totalQty = 0;

    for(const variant of variants) {
      totalQty += variant.qty
    }

    return totalQty
  }

  generateLineItemsForTax(rate) {
    const items = [];

    const designs = this.cart?.designs
    let counter = 1;  // Initialize the counter outside the loop

    for(const design of designs) {
      let item = {
        "number": counter.toString(),
        "quantity": this.getTotalProductQty(design.products.variants),
        "amount": this.getProductPrice(design.products.variants),
        "taxCode": "PC040100",
        "itemCode": `${design.products.brand} ${design.products.style}`,
        "description": `${design.designName}`
      }
      items.push(item);  // Add the item to the items array
      counter++;  // Increment the counter for the next iteration
    }

    if(this.currentRate?.rushFee) {
      let rushFeeItem = {
        "number": counter.toString(),
        "quantity": 1,
        "amount": this.currentRate?.rushFee,
        "taxCode": "P0000000",
        "itemCode": "rushfee",
        "description": "Rush Fee"
      }

      items.push(rushFeeItem)
      counter++
    }

    if(rate) {
      let shippingItem = {
        "number": counter.toString(),
        "quantity": 1,
        "amount": rate.rate,
        "taxCode": "P0000000",
        "itemCode": `UPS ${rate.service}`,
        "description": rate.description
      }

      items.push(shippingItem)
      if(this.totalDiscount) {
      counter++
      }
    }

    if(this.totalDiscount) {
      let discountItem = {
        "number": counter.toString(),
        "quantity": 1,
        "amount": -this.totalDiscount,
        "taxCode": "P0000000",
        "itemCode": `Discount`,
        "description": `Discounted amount on transaction`
      }

      items.push(discountItem)
    }
    return items
  }

  getTaxRates(address, rate) {
    const customerCode =  this.clientId.toString()
    const shipTo = this.formatTaxDTO(address)
    const lineItems = this.generateLineItemsForTax(rate)
    const description = this.cart.cartName

    this._mongo.getTax(customerCode, shipTo, lineItems, description)
    .then((taxResponse: any) => {
      if (taxResponse) {
        this.tax = taxResponse.data.totalTax
      }
    })
    .catch(error => {
      console.error('Error getting tax:', error);
      // Handle error if needed
    });
  }

  transformShippingRates(rates) {
    this.shippingRates = []
    rates.forEach((r) => {
      if (r.service === "Ground") {
        const sevenDay: ShippingRate = {
          option: 'sevenDay',
          title: 'Standard',
          rate: this.boxCount ? ((r.rate * this.boxCount) * 1.2) : r.rate * 1.2,
          deliveryDate: r.delivery_date ? this.addBusinessDays(r.delivery_date, 7) : 'N/A',
          service: r.service,
          description: "7 business days production time + standard ground shipping."
        }
        this.shippingRates.push(sevenDay)

        const fourDay: ShippingRate = {
          option: 'fourDay',
          title: 'Rush',
          rate: this.boxCount ? ((r.rate * this.boxCount) * 1.2) : r.rate * 1.2,
          deliveryDate: r.delivery_date ? this.addBusinessDays(r.delivery_date, 4) : 'N/A',
          rushFee: 100,
          service: r.service,
          description: "4-day production time + standard ground shipping. Rush fees apply."
        }
        this.shippingRates.push(fourDay)
      }
      if (r.service === "2ndDayAir") {
        const threeDay: ShippingRate = {
          option: 'threeDay',
          title: 'Rush + Expedited Shipping',
          rate: this.boxCount ? ((r.rate * this.boxCount) * 1.2) : r.rate * 1.2,
          deliveryDate: r.delivery_date ? this.addBusinessDays(r.delivery_date, 3) : 'N/A',
          rushFee: 150,
          service: r.service,
          description: "3-day production time + 2nd-day air shipping. Rush fees apply."
        }
        this.shippingRates.push(threeDay)
      }
      if (r.service === "NextDayAir") {
        const twoDay: ShippingRate = {
          option: 'twoDay',
          title: 'Fastest',
          rate: this.boxCount ? ((r.rate * this.boxCount) * 1.2) : r.rate * 1.2,
          deliveryDate: r.delivery_date ? this.addBusinessDays(r.delivery_date, 2) : 'N/A',
          rushFee: 250,
          service: r.service,
          description: "2-day production time + Next-day air shipping. Rush fees apply."
        }
        this.shippingRates.push(twoDay)
      }
    })
  }

  addBusinessDays(originalDate, businessDaysToAdd) {
    let weekdaysToAdd = businessDaysToAdd + (businessDaysToAdd / 5) * 2;
    const newDate = new Date(originalDate);

    while (weekdaysToAdd > 0) {
      newDate.setDate(newDate.getDate() + 1);

      // Skip weekends (Saturday and Sunday)
      if (newDate.getDay() !== 0 && newDate.getDay() !== 6) {
        weekdaysToAdd--;
      }
    }

    let result = this.helper.formatDateForCart(newDate);

    if(result.includes("1970")) {
      return null
    } else {
      return this.helper.formatDateForCart(newDate);
    }
  }

  applyShippingRate(o) {
    this.currentRate = o
    this.getTaxRates(this.validatedAddress, o);
    this.form.get('deliveryDate').setValue(o.deliveryDate)
  }

  submitTax(address, rate) {
    const customerCode =  this.clientId.toString()
    const shipTo = this.formatTaxDTO(address)
    const lineItems = this.generateLineItemsForTax(rate)
    const description = this.cart.cartName

    return this._mongo.commitTax(customerCode, shipTo, lineItems, description)
  }

  getLastFourDigits(account: string): string {
    const length = account.length;
    return length >= 4 ? account.slice(length - 4) : ""; // If less than 4, return empty string
  }

  checkPromoCode() {
    this._mongo.validatePromoCode(this.promoCode)
    .then((promoCodeResponse: PromoCode) => {
      if (promoCodeResponse) {
        this.promoCodeInvalid = false
        this.discount = promoCodeResponse
        const recalcWithDiscount = this.calculateGrandTotal();

        if(recalcWithDiscount && recalcWithDiscount > 0) {
          this.getTaxRates(this.validatedAddress, this.currentRate);
        } 
      }
    })
    .catch(error => {
      this.promoCodeInvalid = true
      this.discount = null;
      this.totalDiscount = 0;

      if(!this.validatedAddress) {
        this.promoCodeErrorMessage = 'Please enter a valid shipping address.'
      } else {
        this.promoCodeErrorMessage = 'Your code is invalid. Please try another code'
      }

      const recalc = this.calculateGrandTotal();

      if(recalc && recalc > 0) {
        this.getTaxRates(this.validatedAddress, this.currentRate);
      }
      console.error('Promo code is invalid:', error);
      // Handle error if needed
    });
  }

    // Calculate the grand subtotal for all products
  calculateGrandSubtotal() {
    this.subtotal = 0;
    if (this.cart && this.cart.designs) {
      for (const design of this.cart.designs) {
        this.subtotal += this.getProductPrice(design.products.variants);
      }
    }
    return this.subtotal
  }

  // calculate grand total of cart
  calculateGrandTotal() {
    const totalBeforeDiscount = this.subtotal + (this.currentRate?.rate ?? 0) + (this.currentRate?.rushFee ?? 0) + this.tax;

    if (this.discount) {
      if (this.discount.type === 'percentage') {
        const discountAmount = totalBeforeDiscount * (this.discount.discount / 100);
        this.grandTotal = totalBeforeDiscount - discountAmount;
      } else if (this.discount.type === 'fixed') {
        this.grandTotal = totalBeforeDiscount - this.discount.discount;
      } else {
        this.grandTotal = totalBeforeDiscount;
      }
      this.totalDiscount = totalBeforeDiscount - this.grandTotal
    } else {
      this.grandTotal = totalBeforeDiscount;
    }

    // convert to currency format
    const formattedTotal = this.grandTotal.toFixed(2);
    this.form.get('amount').setValue(formattedTotal);

    return this.grandTotal;
  }

  resolved(captchaResponse: string) {
    this.isNotARobot = captchaResponse?.length > 0
    this.recaptchaToken = captchaResponse
  }

  ngOnDestroy() {
    // Unsubscribe to prevent memory leaks when the component is destroyed
    this.destroyed$.next()
    this.destroyed$.complete()
  }
}
